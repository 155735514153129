// import 'dotenv/config';
import { createClient } from '@supabase/supabase-js'
import { Database } from '../libs/admin/types'

export const supabase = createClient<Database>(
    process.env.REACT_APP_SUPABASE_URL,
    process.env.REACT_APP_SUPABASE_ANON_KEY,
    {
        db: {
            schema: 'public',
        },
        auth: {
            persistSession: true,
        },
    },
)
