import { MetaQuery } from '../../interfaces'
import { useDataProvider } from './useDataProvider'
import useSWRMutation from 'swr/mutation'

import { useRevalidateDataStore } from './useRevalidateDataStore'

interface RPCFunctionParams {
    resource: string
    variables?: object
    meta?: MetaQuery
}

export type MutationArgs = {
    arg: RPCFunctionParams
}

export const useRPCFunction = ({ queryKey }: { queryKey: string }) => {
    const dataProvider = useDataProvider()

    const { revalidate } = useRevalidateDataStore()

    const { data, trigger, error, isMutating } = useSWRMutation(
        queryKey,
        async (_, { arg }: MutationArgs) => {
            const { resource, variables, meta } = arg
            return await dataProvider().rpcFunction?.({
                resource,
                variables,
                meta,
            })
        },
        {
            onSuccess: response => {
                revalidate()
                return response
            },
        },
    )

    return {
        data,
        error,
        trigger,
        isLoading: isMutating,
    }
}
