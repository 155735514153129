import { Table } from '@tanstack/react-table';

interface TableArgs<T extends object> {
    table: Table<T>
}

export const PaginationUI = <T extends object>( {table} : TableArgs<T>) => (
    <div className="flex items-center justify-end gap-2 text-xs text-gray-600 py-5">
        <button
            className="cursor-pointer hover:text-black border rounded py-1 px-2 bg-slate-300 disabled:bg-gray-200  disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-default"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
        >
            First
        </button>
        <button
            className="cursor-pointer hover:text-black border rounded py-1 px-2 bg-slate-300 disabled:bg-gray-200  disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-default"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
        >
            {'<'}
        </button>
        <span className="flex items-center gap-2 border rounded py-1 px-2 bg-slate-300 disabled:bg-gray-200  disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-default">
            <div>Page</div>
            <strong>
                {table.getPageCount()
                    ? table.getState().pagination.pageIndex + 1
                    : 0}{' '}
                of {table.getPageCount()}
            </strong>
        </span>
        <button
            className="cursor-pointer hover:text-black border rounded py-1 px-2 bg-slate-300 disabled:bg-gray-200  disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-default"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
        >
            {'>'}
        </button>
        <button
            className="cursor-pointer hover:text-black border rounded py-1 px-2 bg-slate-300 disabled:bg-gray-200  disabled:text-gray-400 disabled:border-gray-300 disabled:cursor-default"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
        >
            Last
        </button>
    </div>
);
