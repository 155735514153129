import { MetaQuery, CrudFilters } from '../../interfaces'
import { useDataProvider } from './useDataProvider'
import useSWR from 'swr'

export interface GetOneParams {
    queryKey: string
    resource: string
    id: string
    meta?: MetaQuery
    filters?: CrudFilters
}

export const useGetOne = ({ queryKey, resource, id, meta, filters }: GetOneParams) => {
    const dataProvider = useDataProvider()

    const { data, isLoading, error } = useSWR(queryKey, async () => {
        return await dataProvider().getOne({
            resource,
            id,
            filters,
            meta,
        })
    })

    return {
        data,
        isLoading,
        error,
    }
}
