import React, { useEffect } from 'react'
import { ReactLocation, Router } from '@tanstack/react-location'

import { routes } from '@/routes/app.routes'

// main routing component
import { Route } from '@/routes/routes.component'

import '@/App.css'
import { Admin } from '@/libs/admin/core/admin'
import { supabase } from '@/utils/supabaseClient'
import { SupabaseAuthProvider, SupabaseDataProvider } from '@/providers'
import { SWRConfig } from 'swr'
import { useRevalidateAuthStore } from '@/libs/admin'

// set up react location instance
const location = new ReactLocation()

const ReactLocationDevtools =
    process.env.NODE_ENV === 'production'
        ? () => null // Render nothing in production
        : React.lazy(() =>
              // Lazy load in development
              import('@tanstack/react-location-devtools').then(res => ({
                  default: res.ReactLocationDevtools,
                  // For Embedded Mode
                  // default: res.TanStackRouterDevtoolsPanel
              })),
          )

const authProvider = SupabaseAuthProvider(supabase)
const dataProvider = SupabaseDataProvider(supabase)

const App = () => {
    return (
        <SWRConfig
            value={{
                dedupingInterval: 3000,
                provider: () => new Map(),
            }}
        >
            <MainApp />
        </SWRConfig>
    )
}

const MainApp = () => {
    const { revalidate } = useRevalidateAuthStore()

    useEffect(() => {
        if (
            localStorage.theme === 'dark' ||
            window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
            localStorage.theme = 'dark'
            document.documentElement.classList.add('dark')
        } else {
            localStorage.theme = 'light'
            document.documentElement.classList.remove('dark')
        }
    }, [])

    useEffect(() => {
        let hasSession = false

        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, _session) => {
            revalidate()

            // on initial load if there is session change the flag
            // the session state will updated at the bottom
            if (_event == 'INITIAL_SESSION') {
                if (_session) hasSession = true
            }

            // this event will be fired when:
            // 1. user logins via login link
            // 2. when the use change the tabs
            // we need to filter if there is already a session
            // we don't need to reupdate the state object
            // this was the main issue faced while using
            // supabase auth.
            // on every tab change the app was re-rendering unncecessarily
            if (_event == 'SIGNED_IN') {
                if (hasSession) {
                    return
                } else {
                    hasSession = true
                    return
                }
            }

            if (_event == 'SIGNED_OUT') {
                deleteFromLocalStorage()
                hasSession = false
            }
        })
        return () => subscription.unsubscribe()
    }, [])

    return (
        <Admin
            authProvider={authProvider}
            dataProvider={dataProvider}
        >
            <Router
                location={location}
                routes={routes}
            >
                <Route />
                <ReactLocationDevtools initialIsOpen={false} />
            </Router>
        </Admin>
    )
}

export default App

function deleteFromLocalStorage() {
    for( const k in window.localStorage) {
        if( k == 'theme' ) continue;
        // use your preferred method there - maybe an array of keys to exclude?
        delete window.localStorage[k];
     }
}