import { useMemo, memo } from 'react'
import {
    flexRender,
    useReactTable,
    getPaginationRowModel,
    ColumnDef,
    getCoreRowModel,
} from '@tanstack/react-table'

import { PaginationUI } from './pagination'

interface IExpense {
    amount: number
    memo: string | null
    remaining: number
    category: string
}

interface TableArgs {
    data: IExpense[] | null
}

interface PaginationTableArgs<T extends object> {
    data: T[]
    columns: ColumnDef<T>[]
    showNavigation?: boolean
}

export const TableWithPagination = <T extends object>({
    data,
    columns,
    showNavigation = true,
}: PaginationTableArgs<T>) => {
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    })

    const headerGroups = table.getHeaderGroups()

    const headerRowOpt = headerGroups.map(headerGroup => (
        <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
                const enableOnClick =
                    (header.column.columnDef.header as string).toLowerCase() == 'amount'
                return (
                    <th
                        key={header.id}
                        className='text-center font-normal text-sm'
                        onClick={
                            enableOnClick ? header.column.getToggleSortingHandler() : undefined
                        }
                    >
                        {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                )
            })}
        </tr>
    ))

    const rows = table.getRowModel().rows

    const bodyOpt = (rows.length > 0 &&
        rows.map(row => (
            <tr
                className='my-4 rounded-lg shadow-md bg-bglight h-9 border-b-0.1 border-gray-300 dark:bg-textDark2'
                key={row.id}
            >
                {row.getVisibleCells().map(cell => (
                    <td
                        key={cell.id}
                        className='text-center text-sm'
                    >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                ))}
            </tr>
        ))) || (
        <tr className='bg-white text-slate-600 text-sm'>
            <td
                className='text-center py-8'
                colSpan={4}
            >
                No Expense Data Available
            </td>
        </tr>
    )

    return (
        <>
            <div className='h-100'>
                <table className='w-full px-4 border-collpase border-spacing-y-3 rounded'>
                    <thead className='h-10 rounded text-bglight bg-tealdark dark:bg-hoverDark'>
                        {headerRowOpt}
                    </thead>
                    <tbody>{bodyOpt}</tbody>
                </table>
            </div>
            {showNavigation ? <PaginationUI table={table} /> : null}
        </>
    )
}

export const mReactTable = ({ data }: TableArgs) => {
    if (!data) {
        return <div>Loading...</div>
    }

    const cols = useMemo<ColumnDef<IExpense>[]>(
        () => [
            {
                header: 'Date',
                cell: row => (row.getValue() as string).split('T')[0],
                accessorKey: 'created_at',
            },
            {
                header: 'Purpose',
                cell: row => row.renderValue(),
                accessorKey: 'memo',
            },
            {
                header: 'Category',
                cell: row => row.renderValue(),
                accessorKey: 'category',
            },
            {
                header: 'Amount',
                cell: row => row.renderValue(),
                accessorKey: 'amount',
            },
        ],
        [],
    )

    return (
        <>
            <TableWithPagination
                data={data}
                columns={cols}
            />
        </>
    )
}

export const ReactTable = memo(mReactTable)
