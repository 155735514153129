import { SupabaseClient } from '@supabase/supabase-js'
import { IAuthContextProvider as AuthProvider } from '@admin/contexts/auth/IAuthContext'

export const SupabaseAuthProvider = (supabaseClient: SupabaseClient): AuthProvider => {
    return {
        register: async ({ email, password, name }) => {
            const { data, error } = await supabaseClient.auth.signUp({
                email,
                password,
                options: {
                    data: { name },
                    emailRedirectTo: 'https://pet.kapilbastola.com.np/onboarding',
                },
            })
            if (error) {
                return {
                    success: false,
                    error: error || {
                        message: 'Register failed',
                        name: 'Invalid Credentials',
                    },
                }
            }

            if (data) {
                return {
                    success: true,
                    data: data
                }
            }

            return {
                success: false,
                error: {
                    message: 'Register failed',
                    name: 'Invalid Credentials',
                },
            }
        },
        login: async ({ email, password }) => {
            const { data, error } = await supabaseClient.auth.signInWithPassword({
                email,
                password,
            })

            if (error != null) {
                return {
                    success: false,
                    error: error || {
                        message: 'Login failed',
                        name: 'Invalid Credentials',
                    },
                }
            }

            if (data) {
                return {
                    success: true,
                    redirectTo: '/',
                }
            }

            return {
                success: false,
                error: {
                    message: 'Login failed',
                    name: 'Invalid Credentials',
                },
            }
        },
        logout: async () => {
            const { error } = await supabaseClient.auth.signOut()

            if (error) {
                return {
                    success: false,
                    error: error || {
                        message:
                            'Logout failed. All the edge cases for logout should be resolved. Please look into the problem',
                        name: 'Unknown Error: Logout Error',
                    },
                }
            }

            return {
                success: true,
                redirectTo: '/login',
            }
        },
        check: async () => {
            const {
                data: { session },
                error,
            } = await supabaseClient.auth.getSession()

            if (!session) {
                return {
                    authenticated: false,
                    error: error || {
                        message: 'Couldn\'t determine the authenticated status of the session',
                        name: 'Session Not Found',
                    },
                    redirectTo: '/login',
                }
            }

            return {
                authenticated: true,
            }
        },
        onError: async error => {
            console.log(error)
            return { error }
        },
        getPermissions: async () => {
            const {
                data: { user },
            } = await supabaseClient.auth.getUser()

            if (user) {
                return user.role
            }

            return null
        },
        getIdentity: async () => {
            const {
                data: { user },
            } = await supabaseClient.auth.getUser()

            if (user) {
                return user
            }

            return null
        },
        getSession: async () => {
            const {
                data: { session },
            } = await supabaseClient.auth.getSession()

            if (session) {
                return session
            }

            return null
        },
    }
}
