import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import { useDateRange } from '../../context/useDateRange'
import { HorizontalDivider } from '../divider'

export const DateRangePicker = () => {
    const { fromDate, toDate } = useDateRange()
    const [startDate, updateStartDate] = fromDate
    const [endDate, updateEndDate] = toDate
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    return (
        <div className='flex items-center justify-evenly text-sm my-2 w-4/5'>
            <p className='text-gray-700 dark:text-textDark2'>From:</p>
            <DatePicker
                selected={startDate}
                onChange={(date: Date) => updateStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={startDate}
                maxDate={yesterday}
                className='p-1 text-sm rounded'
                wrapperClassName='w-40'
            />
            <HorizontalDivider gap='8' />
            <p className='text-gray-700 dark:text-textDark2'>To:</p>
            <DatePicker
                selected={endDate}
                onChange={(date: Date) => updateEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                maxDate={today}
                className='p-1 text-sm rounded'
                wrapperClassName='w-40'
            />
        </div>
    )
}
