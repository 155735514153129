import { useNavigate, UseNavigateType } from '@tanstack/react-location'
import {
    ChangeEvent,
    ChangeEventHandler,
    FocusEventHandler,
    FormEvent,
    FormEventHandler,
    useState,
    useEffect,
    useRef,
} from 'react'
import { Register } from './register.component'
import { useRegister } from '@/libs/admin'
import { HttpError } from '@/libs/admin/interfaces/errors'
import { ZodError } from 'zod'
import { registerValidationSchema } from '@/validationSchemas'

interface IUser {
    email: string
    password: string
    name: string
}

export interface IRegisterProps {
    formState: IUser
    error: Error | HttpError | null
    success: boolean
    isLoading: boolean
    showError: boolean
    navigate: UseNavigateType
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void
    handleRegister: (event: FormEvent<HTMLFormElement>) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleFocus: (event: any) => void
}

// This container contains all the logical implementation of the component
// It contains event handlers, api calls, state handling functions

const RegisterContainer = () => {
    const navigate = useNavigate()

    const { success, handleRegister: register, isProcessing, error: registerError } = useRegister()

    const [showError, setShowError] = useState(false)

    const error = useRef<HttpError | Error | null>(null)

    useEffect(() => {
        if (registerError) error.current = registerError
        setShowError(!isProcessing && !!registerError)
    }, [registerError])

    const [formState, setFormState] = useState({
        email: '',
        password: '',
        name: '',
    })

    const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
        setFormState({ ...formState, [e.target.name]: e.target.value })
        setShowError(false)
    }

    const handleRegister: FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault()
        try {
            const validatedForm = registerValidationSchema.parse(formState)
            register(validatedForm)
        } catch (err) {
            error.current = formatZodError(err)
            setShowError(true)
        }
    }

    const handleFocus: FocusEventHandler<HTMLInputElement> = () => {
        error.current = null
        setShowError(false)
    }

    const props: IRegisterProps = {
        formState,
        error: error.current,
        success,
        isLoading: isProcessing,
        handleChange,
        handleRegister,
        handleFocus,
        showError,
        navigate,
    }
    return <Register {...props} />
}

export { RegisterContainer as Register }

function formatZodError(error: unknown) {
    return (error as ZodError).errors[0] as any
}
