import { useDataProvider } from './useDataProvider'
import { BaseKey, MetaQuery } from '../../interfaces'
import useSWRMutation from 'swr/mutation'

export interface UpdateOneParams {
    resource: string
    id: BaseKey
    variables: object
    meta?: MetaQuery
}

type MutationArgs = {
    arg: UpdateOneParams
}

export const useUpdateOne = ({ queryKey }: { queryKey: string }) => {
    const dataProvider = useDataProvider()

    const {
        isMutating: isProcessing,
        trigger: update,
        data,
        error,
    } = useSWRMutation(
        queryKey,
        async (_, { arg }: MutationArgs) => {
            return dataProvider().update(arg)
        },
        {
            onSuccess: data => {
                return data?.data
            },
            onError: err => {
                throw err.message
            },
        },
    )

    return {
        isProcessing,
        data,
        update,
        error,
    }
}
