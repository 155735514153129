interface CardProps {
    icon?: React.ReactNode
    amount: string | number
    type?: string
    month?: string
}

export const ExpenseCard = ({ type, amount, icon }: CardProps) => {
    return (
        <div className='flex flex-col first:ml-0 items-start p-3 w-32 dark:bg-hoverDark drop-shadow-sm bg-bglight rounded'>
            <span className="bg-iconBg p-1 rounded-md">{icon ? icon : null}</span>
            <p className='text-xs text-tealdark dark:text-textDark3 capitalize mt-4 w-full'>{type}</p>
            <p className='text-lg font-semibold dark:text-textDark2'>
                {amount}
            </p>
        </div>
    )
}
