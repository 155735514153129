import { PropsWithChildren, ReactElement } from 'react'

interface DataCosumerProps extends PropsWithChildren<object> {
    loader: ReactElement
    emptyDataFallback?: ReactElement
    isLoading: boolean
    emptyData?: boolean
}

export const DataConsumer = ({
    loader,
    emptyDataFallback,
    isLoading,
    emptyData,
    children,
}: DataCosumerProps) => {
    if (isLoading) {
        return <>{loader}</>
    }

    if (emptyData) {
        return <>{emptyDataFallback}</>
    }
    return <>{children}</>
}
