import { memo } from 'react'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

// expense card
import { ExpenseCard } from '../expense-card/expense-card'

import { getIconProps, BankNotesIcon } from '../../assets/heroicons'

import { icons } from '../../assets/icons'
import { useGetOne, GetOneParams } from '../../libs/admin/hooks/data/useGetOne'
import { DataConsumer } from '../data/DataCosumer'
import { useGetSession } from '@/libs/admin'
import { Session } from '@supabase/supabase-js'

interface IExpenseByCategory {
    home: number
    study: number
    travel: number
    friends: number
    work: number
    food: number
    clothes: number
    miscellaneous: number
}

const currentMonthYear = new Date().toLocaleDateString('default', {
    month: 'long',
    year: 'numeric',
})

export const IExpenseReport = () => {
    const { data: sessionData, isProcessing: isLoadingUser } = useGetSession()
    const session = sessionData as Session
    const userId = session?.user?.id

    const { data: report, isLoading: isLoadingReport } = useGetOne(
        generateMonthlyReportParams(userId as string),
    )

    const { data: categories, isLoading: isLoadingCategories } = useGetOne(
        generateExpenseCategoryParams(userId as string),
    )

    const reportData = report?.data
    let categoriesData = categories?.data as IExpenseByCategory

    const isLoading = isLoadingReport || isLoadingCategories

    if (!isLoadingCategories) {
        categoriesData = sortCategoriesByAmount(categoriesData) as IExpenseByCategory
    }

    return (
        <div className='flex flex-col w-full'>
            <p className='text-2xl text-start text-tealdark dark:text-tealmedium'>
                {currentMonthYear}
            </p>
            <div className='expense-report grid grid-cols-horizontal-overflow auto-cols-horizontal-overflow grid-flow-col scrollbar-thin scrollbar-thumb-tealdark scrollbar-thumb-rounded-md scrollbar-track-gray-50 scrollbar-track-rounded-md hover:cursor-pointer overflow-x-scroll py-2 px-0 gap-1 w-54vw'>
                <DataConsumer
                    isLoading={isLoading || isLoadingUser}
                    loader={<Loader />}
                >
                    <ExpenseCard
                        type='expenditure'
                        amount={reportData?.expenditure ?? 0}
                        icon={<BankNotesIcon {...getIconProps('Bank Note')} />}
                    />

                    {Object.keys({ ...categoriesData }).map(category => {
                        const cKey = category as keyof typeof categoriesData
                        const icon = (
                            <img
                                src={icons[cKey]}
                                className='w-4 h-4'
                            />
                        )
                        return (
                            <ExpenseCard
                                type={category}
                                amount={categoriesData[cKey]}
                                key={category}
                                icon={icon}
                            />
                        )
                    })}
                </DataConsumer>
            </div>
        </div>
    )
}

const Loader = () => (
    <SkeletonTheme
        baseColor='#22303c'
        highlightColor='#2e4152'
    >
        {[1, 2, 3, 4, 5].map(index => (
            <Skeleton
                count={1}
                height={105}
                width={135}
                key={index}
                enableAnimation
            />
        ))}
    </SkeletonTheme>
)

function sortCategoriesByAmount(categoriesData: IExpenseByCategory) {
    const entries = Object.entries(categoriesData)

    // Sort the array based on the values
    entries.sort((a, b) => b[1] - a[1])

    // Reconstruct the object from the sorted array
    return Object.fromEntries(entries)
}

function generateExpenseCategoryParams(userId: string) {
    return {
        queryKey: 'data-get-dashboard-category-report',
        resource: 'expense_category_data',
        id: userId,
        filters: [
            {
                field: 'month',
                operator: 'eq',
                value: currentMonthYear.split(' ')[0],
            },
            {
                field: 'year',
                operator: 'eq',
                value: currentMonthYear.split(' ')[1],
            },
        ],
        meta: {
            idColumnName: 'user_id',
            select: 'home, study, work, travel, food, friends, clothes, miscellaneous',
        },
    } as GetOneParams
}

function generateMonthlyReportParams(userId: string) {
    return {
        queryKey: 'data-get-dashboard-expense-report',
        resource: 'monthly_report',
        id: userId,
        filters: [
            {
                field: 'month',
                operator: 'eq',
                value: currentMonthYear.split(' ')[0],
            },
            {
                field: 'year',
                operator: 'eq',
                value: currentMonthYear.split(' ')[1],
            },
        ],
        meta: {
            idColumnName: 'user_id',
            select: 'month, expenditure',
        },
    } as GetOneParams
}

export const ExpenseReport = memo(IExpenseReport)
