interface TitleProps {
    title: string
    detail?: string
}

export const SectionHeader = ({ title, detail }: TitleProps) => (
    <p className='text-start text-gray-700 font-semibold dark:text-textDark3 capitalize'>
        {title}
        {detail ? (
            <span className='text-xxs text-gray-500 dark:text-tealmedium font-semibold ml-1 normal-case'>
                ( {detail} )
            </span>
        ) : null}
    </p>
)
