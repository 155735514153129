import { PropsWithChildren, ReactElement } from 'react'

export enum EButtonVariant {
    PROCEED = 'flex items-center justify-around bg-green-500 hover:bg-green-700 text-white border border-green-600 dark:disabled:bg-transparent dark:disabled:hover:bg-transparent dark:border-transparent dark:disabled:border dark:disabled:border-borderDark dark:disabled:text-textDark2 rounded shadow-lg disabled:bg-gray-400 disabled:text-gray-100 disabled:border-gray-400',
    CANCEL = 'flex items-center justify-around bg-red-600 text-white hover:bg-red-700 border border-red-600 hover:text-teallight hover:border-red-500 rounded disabled:bg-gray-400 disabled:text-gray-100 disabled:border-gray-400',
    DEFAULT = 'flex items-center justify-around bg-tealdark text-teallight hover:bg-tealmedium border border-tealdark rounded shadow-lg drop-shadow-lg disabled:bg-gray-400 disabled:text-gray-100 disabled:border-gray-400',
    SECONDARY = 'flex items-center justify-around bg-orange-600 border border-orange-600 text-white hover:bg-orange-700 dark:bg-transparent dark:border dark:border-green-600 dark:hover:bg-green-600 rounded shadow-xl dark:disabled:bg-transparent dark:disabled:border-borderDark disabled:bg-gray-400 disabled:text-gray-100 disabled:border-gray-400',
    CANCEL_GRAY = 'flex items-center justify-around bg-gray-500 hover:bg-gray-600 border border-gray-500 rounded shadow-lg text-white disabled:bg-gray-400 disabled:text-gray-100 disabled:border-gray-400',
}

export enum EButtonSize {
    SMALL = 'text-xxs p-2 w-16 min-w-max text-white',
    DEFAULT = 'text-sm p-2 w-20 min-w-max',
    LARGE = 'p-2 w-24 text-center w-24 min-w-max',
}

export interface ActionButtonProps extends PropsWithChildren <object> {
    disabled?: boolean
    onClick: () => void
    variant?: EButtonVariant
    size?: EButtonSize
    text: string
    isProcessing?: boolean
    loader?: ReactElement
}

export const ActionButton = ({
    disabled = false,
    onClick,
    variant = EButtonVariant.DEFAULT,
    size = EButtonSize.DEFAULT,
    text,
    isProcessing,
    loader,
}: ActionButtonProps) => {
    return (
        <button
            type='button'
            className={variant + ' ' + size}
            disabled={disabled}
            onClick={onClick}
        >
            {isProcessing && loader}
            {text}
        </button>
    )
}
