import { useNavigate, UseNavigateType } from '@tanstack/react-location'
import {
    useState,
    ChangeEventHandler,
    FormEventHandler,
    FocusEventHandler,
    ChangeEvent,
    FormEvent,
    useEffect,
    useRef,
} from 'react'
import { Login } from './login.component'
import { useLogin } from '@/libs/admin'
import { HttpError } from '@/libs/admin/interfaces/errors'

import { loginValidationSchema } from '@/validationSchemas'
import { ZodError } from 'zod'

interface IUser {
    email: string
    password: string
}

export interface ILoginProps {
    formState: IUser
    error: Error | HttpError | null
    isProcessing: boolean
    showError: boolean | undefined
    navigate: UseNavigateType
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void
    handleLogin: (event: FormEvent<HTMLFormElement>) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleFocus: (event: any) => void
    setDemoAccount: () => void
}

const LoginContainer = () => {
    const [formState, setFormState] = useState({
        email: '',
        password: '',
    })

    const error = useRef<HttpError | Error | null>(null)

    const { handleLogin: login, isProcessing, error: loginErr } = useLogin()
    const [showError, setShowError] = useState(false)

    useEffect(() => {
        if (loginErr) error.current = loginErr
        setShowError(!isProcessing && !!loginErr)
    }, [loginErr])

    const navigate = useNavigate()

    const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
        setFormState({ ...formState, [e.target.name]: e.target.value })
        setShowError(false)
    }

    const handleLogin: FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault()
        try {
            const validatedForm = loginValidationSchema.parse(formState)
            login({ ...validatedForm })
        } catch (err) {
            error.current = formatZodError(err)
            setShowError(true)
        }
    }

    const handleFocus: FocusEventHandler<HTMLInputElement> = () => {
        error.current = null
        setShowError(false)
    }

    const setDemoAccount = () => {
        setFormState({ ...formState, email: 'demo@expensetracker.com', password: 'demouser' })
        setShowError(false)
    }

    const props: ILoginProps = {
        formState,
        error: error.current,
        showError,
        isProcessing,
        navigate,
        handleChange,
        handleLogin,
        handleFocus,
        setDemoAccount
    }
    return <Login {...props} />
}

export { LoginContainer as Login }


function formatZodError(error: unknown){
    return (error as ZodError).errors[0] as any
}