import { useNavigate } from '@tanstack/react-location';
import useSWRMutation from 'swr/mutation'

import { AdminError } from '../../../interfaces/errors/AdminError';
import { useAuthContext } from '../useAuthContext';
import { AuthActionResponse } from '@/libs/admin/contexts/auth/IAuthContext';
import { useState } from 'react';


export type UseRegisterReturnType = {
    isProcessing: boolean
    error: AdminError | Error | undefined
    handleRegister: (params: any) => void
    success: boolean
    [key: string]: unknown
}

export const useRegister = (): UseRegisterReturnType => {
    const { register } = useAuthContext();
    const navigate = useNavigate()

    const [error, setError] = useState<AdminError | Error>()

    const { data, trigger, isMutating} = useSWRMutation(
        'auth-logout',
        async(_, { arg }) => {
            return await register?.(arg)
        },
        {
            onSuccess: (response) => {
                const { success, error, redirectTo, data } = response as AuthActionResponse


                if(success && (data as any)?.user?.identities?.length === 0){
                    setError({
                        name: 'AuthError',
                        message: 'Email already used'
                    })
                    return false
                }

                if(success && redirectTo){
                    navigate({ to: redirectTo, replace: true})
                }

                if (error) {
                    setError(error)
                    return false
                }

                return {
                    success
                }
                 
            },
            onError: err => {
                setError(err)
            },
        }
    )
    return {
        success: !(error as Error) && data?.success,
        isProcessing: isMutating,
        error,
        handleRegister: trigger
    }
}