import useSWR from 'swr'
import { useAuthContext } from '../useAuthContext'
import { IdentityResponse } from '../../../contexts/auth/IAuthContext'

export type UseGetIdentityProps = {
    params: any
}

export interface UseGetIdentityReturnType {
    isProcessing: boolean
    data: IdentityResponse
    [key: string]: any
}

export const useGetIdentity = (params?: UseGetIdentityProps): UseGetIdentityReturnType => {
    const { getIdentity } = useAuthContext()


    const { data, isLoading, error } = useSWR('auth-getIdentity', async() => {
        return await getIdentity?.(params)
    })

    return {
        isProcessing: isLoading,
        data,
        error
    }
}
