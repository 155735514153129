import { generateFilter, handleErrors } from '@admin/utils'
import { IDataContextProvider as DataProvider } from '@admin/contexts/data/IDataContext'
import { SupabaseClient } from '@supabase/supabase-js'

export const SupabaseDataProvider = (supabaseClient: SupabaseClient): Required<DataProvider> => {
    return {
        getList: async ({ resource, pagination, filters, sorters, meta }) => {
            const { current = 1, pageSize = 10, mode = 'client' } = pagination ?? {}

            const query = supabaseClient.from(resource).select(meta?.select ?? '*', {
                count: 'exact',
            })

            if (mode === 'server') {
                query.range((current - 1) * pageSize, current * pageSize - 1)
            }

            if (meta?.limit) {
                query.limit(parseInt(meta.limit))
            }

            sorters?.map(item => {
                // if the query contains takes input from foreign table
                // sorts based on the foreign table field

                const [foreignTable, field] = item.field.split(/\.(.*)/)
                if (foreignTable && field) {
                    query.select(meta?.select ?? `*, ${foreignTable}(${field})`)
                } else {
                    query.order(item.field, {
                        ascending: item.order === 'asc',
                    })
                }
            })

            filters?.map(item => {
                generateFilter(item, query)
            })

            const { data, count, error } = await query

            if (error) {
                return handleErrors(error)
            }

            return {
                data: data || [],
                total: count || 0,
            } as any
        },
        getOne: async ({ resource, id, filters, meta }) => {
            const query = supabaseClient.from(resource).select(meta?.select ?? '*')

            if (meta?.idColumnName) {
                query.eq(meta.idColumnName, id)
            } else {
                query.match({ id })
            }

            filters?.map(item => {
                generateFilter(item, query)
            })

            const { data, error } = await query

            if (error) {
                handleErrors(error)
            }

            return {
                data: (data || [])[0] as any,
            }
        },
        getMany: async ({ resource, ids, meta }) => {
            const query = supabaseClient.from(resource).select(meta?.select ?? '*')

            if (meta?.idColumnName) {
                query.in(meta?.idColumnName, ids)
            } else {
                query.in('id', ids)
            }

            const { data, error } = await query

            if (error) {
                handleErrors(error)
            }

            return {
                data: data || [],
            } as any
        },
        create: async ({ resource, variables, meta }) => {
            const query = supabaseClient.from(resource).insert(variables)

            if (meta?.select) {
                query.select(meta.select)
            }

            const { data, error } = await query

            if (error) {
                handleErrors(error)
            }

            return {
                data: (data || [])[0] as any,
            }
        },
        createMany: async ({ resource, variables, meta }) => {
            const query = supabaseClient.from(resource).insert(variables)

            if (meta?.select) {
                query.select(meta.select)
            }

            const { data, error } = await query

            if (error) {
                handleErrors(error)
            }

            return {
                data: data || [],
            } as any
        },
        update: async ({ resource, id, variables, meta }) => {
            const query = supabaseClient.from(resource).update(variables)

            if (meta?.idColumnName) {
                query.eq(meta.idColumnName, id)
            }else{
                query.eq('id',id)
            }

            if (meta?.select) {
                query.select(meta.select)
            }

            const { data, error } = await query

            if (error) {
                handleErrors(error)
            }

            return {
                data: (data || [])[0] as any,
            }
        },
        updateMany: async ({ resource, ids, variables, meta }) => {
            const response = await Promise.all(
                ids.map(async id => {
                    const query = supabaseClient.from(resource).update(variables)

                    if (meta?.idColumnName) {
                        query.eq(meta.idColumnName, id)
                    } else {
                        query.eq('id', id)
                    }

                    if (meta?.select) {
                        query.select(meta.select)
                    }

                    const { data, error } = await query

                    if (error) {
                        handleErrors(error)
                    }

                    return (data || [])[0] as any
                }),
            )

            return {
                data: response,
            } as any
        },
        deleteOne: async ({ resource, id, meta }) => {
            const query = supabaseClient.from(resource).delete()

            if (meta?.idColumnName) {
                query.eq(meta.idColumnName, id)
            } else {
                query.eq('id', id)
            }

            if (meta?.select) {
                query.select(meta.select)
            }

            const { data, error } = await query

            if (error) {
                handleErrors(error)
            }

            return {
                data: (data || [])[0] as any,
            }
        },
        deleteMany: async ({ resource, ids, meta }) => {
            const response = await Promise.all(
                ids.map(async id => {
                    const query = supabaseClient.from(resource).delete()

                    if (meta?.idColumnName) {
                        query.eq(meta.idColumnName, id)
                    } else {
                        query.eq('id', id)
                    }

                    if (meta?.select) {
                        query.select(meta.select)
                    }

                    const { data, error } = await query

                    if (error) {
                        handleErrors(error)
                    }

                    return (data || [])[0] as any
                }),
            )

            return {
                data: response,
            } as any
        },
        rpcFunction: async ({ resource, variables }) => {
            let query
            if (variables) {
                query = supabaseClient.rpc(resource, variables)
            } else {
                query = supabaseClient.rpc(resource)
            }

            const { data, error } = await query

            if (error) {
                handleErrors(error)
            }

            return {
                data: data || [],
            } as any
        },
        getApiUrl: () => {
            throw new Error(
                'Not implemented. If you are going to use this function. Please define it.',
            )
        },
        custom: () => {
            throw new Error(
                'Not implemented. If you are going to use this function. Please define it.',
            )
        },
    }
}
