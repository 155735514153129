import { useState } from 'react'
import { ColumnContainer } from '../../Layouts/ColumnContainer'
import { ExpenseAddForm } from '../expense-add-form/expense-add-form'
import { ExpenseHistory } from '../expense-history/expense-history'
import { HighestExpense } from './highest-expense'
import { getIconProps, BankNotesIcon } from '../../assets/heroicons'
import { ActionButton } from '../button'
import { supabase, useGetSession } from '@/libs/admin'
import { Session, User } from '@supabase/supabase-js'

interface IAddButton {
    handleAddClick: () => void
}

export const ExpenseInfo = () => {
    const [showAddForm, setShowAddForm] = useState<boolean>(false)

    const { data: sessionData } = useGetSession()
    const session = sessionData as Session
    const user = session?.user

    const handleAddClick = () => {
        setShowAddForm(!showAddForm)
        getExpensesDataForReport(user)
            .then(data => {
                console.log(data)
            })
            .catch(error => {
                console.log(error.message)
            })
    }

    if (showAddForm) {
        return (
            <div className='flex flex-col items-center justify-start w-1/4 h-screen bg-bgMain dark:bg-bgDark min-hs-screen py-6 px-2'>
                <ExpenseAddForm setShowAddForm={setShowAddForm} />
            </div>
        )
    }

    return (
        <ColumnContainer>
            <div className='bg-bglight dark:bg-hoverDark rounded shadow-md drop-shadow w-11/12 mt-12'>
                <HighestExpense />
                <p className='border-b-0.1 border-gray-600'></p>
                <ExpenseHistory />
            </div>
            <AddButton handleAddClick={handleAddClick} />
        </ColumnContainer>
    )
}

const AddButton = ({ handleAddClick }: IAddButton) => {
    return (
        <div className='mt-2 p-4 bg-bglight dark:bg-cardDark shadow-md drop-shadow-md w-11/12 rounded flex items-center justify-between'>
            <div className='flex flex-col justify-center items-center'>
                <BankNotesIcon {...getIconProps('Bank Note', 'large')} />
                <p className='text-textDark2 font-bold'>New Transaction</p>
            </div>
            <ActionButton
                text='Add'
                onClick={handleAddClick}
            />
        </div>
    )
}

async function getExpensesDataForReport(user: User) {
    // 3. Initialise today's date
    const firstDayofMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 2)
        .toISOString()
        .split('T')[0]
    const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth())
        .toISOString()
        .split('T')[0]

    const expenses: any = []

    try {
        const { data, error } = await supabase
            .from('expenses')
            .select('created_at , memo, category, amount')
            .eq('user_id', user.id)
            .gte('created_at', firstDayofMonth)
            .lte('created_at', lastDayOfMonth)

        if (error) {
            throw new Error(error.message)
        }
        expenses.push({
            ...user,
            data: data,
        })
    } catch (error: any) {
        console.error(error.message)
    }

    return expenses
}
