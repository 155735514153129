import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Session } from '@supabase/supabase-js'

import { UserIcon } from '@/assets/icons'
import { GetOneParams, useGetOne, useGetSession } from '@/libs/admin'
import { DataConsumer, LazyImage } from '@/components/'

interface ImageProps {
    image: string
}

interface User {
    name: string
    email: string
    image: string
}

export const ImageCard = ({ image }: ImageProps) => {
    return (
        <div className='w-24 my-2'>
            <LazyImage 
                src={image}
                placeholderSrc={UserIcon}
                className='shadow-md rounded-full w-11/12 m-auto'
                placeholderClassName='shadow-md rounded-full w-11/12 m-auto'
            />
        </div>
    )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const User = () => {
    const { data: sessionData, isProcessing: isLoadingSession } = useGetSession()
    const session = sessionData as Session
    const userId = session?.user?.id

    const { data, isLoading: isLoadingUser } = useGetOne(generateParamsForGettingUser(userId))
    const user = data?.data as User

    return (
        <DataConsumer
            isLoading={isLoadingSession || isLoadingUser}
            loader={<Loader />}
        >
            <div className='flex flex-col items-center justify-center my-12'>
                <ImageCard image={user?.image} />
                <p className='text-gray-700 dark:text-textDark3 font-semibold text-sm'>
                    {user?.name}
                </p>
            </div>
        </DataConsumer>
    )
}

const Loader = () => (
    <SkeletonTheme
        baseColor='#22303c'
        highlightColor='#2e4152'
    >
        <div className='flex flex-col items-center my-12'>
            <Skeleton
                count={1}
                height={100}
                width={100}
                circle
            />
            <Skeleton
                className='mt-4'
                count={1}
                height={30}
                width={150}
            />
        </div>
    </SkeletonTheme>
)

function generateParamsForGettingUser(userId: string) {
    return {
        queryKey: 'data-get-user',
        resource: 'profiles',
        id: userId,
        meta: {
            select: 'email, name, image',
        },
    } as GetOneParams
}
