import { AdminError } from '../../../interfaces/errors/AdminError'
import { useAuthContext } from '../useAuthContext'
import { useNavigate } from '@tanstack/react-location'
import useSWRMutation from 'swr/mutation'
import { AuthActionResponse } from '@/libs/admin/contexts/auth/IAuthContext'
import { useState } from 'react'

export type UseLogoutParams = {
    params: any
}

export type UseLogoutReturnType = {
    isProcessing: boolean
    error: AdminError | Error | undefined
    handleLogout: (params?: any) => void
    [key: string]: unknown
}

export const useLogout = (): UseLogoutReturnType => {
    const { logout } = useAuthContext()
    const navigate = useNavigate()

    const [error, setError] = useState<AdminError | Error>()

    const { trigger, isMutating } = useSWRMutation(
        'auth-logout',
        async (_, { arg }) => {
            return await logout?.(arg)
        },
        {
            onSuccess: response => {
                const { success, redirectTo, error } = response as AuthActionResponse

                if (success) {
                    if (redirectTo) {
                        navigate({ to: redirectTo, replace: true })
                    } else {
                        navigate({ to: '/login', replace: true })
                    }
                }

                if (error) {
                    setError(error)
                }
            },
            onError: err => {
                setError(err)
            },
        },
    )

    return {
        isProcessing: isMutating,
        error,
        handleLogout: trigger,
    }
}
