import { SubmitButton, VerticalDivider, CircularLoader } from '@/components'
import { ILoginProps } from './login.container'

export const Login = ({ ...props }: ILoginProps) => {
    const {
        formState,
        error,
        isProcessing,
        showError,
        navigate,
        handleChange,
        handleLogin,
        handleFocus,
        setDemoAccount,
    } = props

    let errorMessage

    if (showError) {
        errorMessage = (
            <p className='text-red-600 dark:bg-transparent dark:border dark:border-red-600 dark:text-textDark1 text-center animate-slide-right h-fit bg-gray-100 w-20vw rounded py-3 px-8 last:text-sm font-semibold mt-4 md:absolute top-48 right-10'>
                {error?.message}
            </p>
        )
    }

    return (
        <div className='w-4/5 m-auto bg-white h-full relative dark:w-full dark:bg-bgDark dark:h-screen'>
            <div className='flex flex-col items-center justify-start py-24'>
                <p className='text-4xl font-bold drop-shadow-lg text-tealdark'>
                    P<span className='text-6xl'>€</span>T
                </p>
                <p className='text-sm text-gray-600 mt-3 dark:text-textDark2'>
                    Are you new ?
                    <span
                        className='font-bold text-tealdark underline cursor-pointer ml-2'
                        onClick={() => {
                            navigate({ to: '/register' })
                        }}
                    >
                        Register
                    </span>
                </p>
                <div
                    className='bg-tealdark dark:bg-hoverDark p-8 rounded-md shadow-md text-white z-10  my-2'
                    aria-label='login form'
                >
                    {/* <p className='text-dark text-2xl text-center dark:text-tealmedium'>Sign In</p> */}
                    <p className='text-green-300 text-md text-center mt-2 dark:text-textDark2'>
                        Sign in to see your expenditure history
                    </p>

                    <form
                        id='login-form'
                        className='flex flex-col items-center justify-center mt-4'
                        onSubmit={handleLogin}
                    >
                        <div className='flex items-center justify-center mt-4 w-full'>
                            <label
                                className='w-20'
                                htmlFor='email'
                            >
                                Email
                            </label>
                            <input
                                id='email'
                                name='email'
                                className='p-2 rounded text-black w-60'
                                type='email'
                                placeholder='email@address.com'
                                value={formState.email}
                                onChange={handleChange}
                                onFocus={handleFocus}
                            />
                        </div>
                        <div className='flex items-center justify-center mt-4 w-full'>
                            <label
                                className='w-20'
                                htmlFor='password'
                            >
                                Password
                            </label>
                            <input
                                id='password'
                                name='password'
                                className='p-2 rounded text-black w-60'
                                type='password'
                                placeholder='******'
                                value={formState.password}
                                onChange={handleChange}
                                onFocus={handleFocus}
                            />
                        </div>
                        <VerticalDivider gap='6' />
                        <SubmitButton
                            formName='login-form'
                            disabled={isProcessing}
                            aria-live='polite'
                            isSubmitting={isProcessing}
                            text='Sign In'
                            loader={<Loader />}
                        />
                    </form>
                    <div className='flex justify-center items-center mt-6 text-xs gap-1'>
                        <p>Just for a quick visit?</p>
                        <p
                            className='text-teallight dark:text-tealmedium font-medium cursor-pointer underline'
                            onClick={setDemoAccount}
                        >
                            Use Demo Account
                        </p>
                    </div>
                </div>
            </div>
            {errorMessage}
        </div>
    )
}

const Loader = () => (
    <CircularLoader
        height='h-6'
        width='w-6'
        color='border-orange-600'
    />
)
