import { Link } from '@tanstack/react-location'

import { HistoryCard } from './history-card'

import { SectionHeader } from '../section-header/section-header'

import { TExpense } from '../../types'

// Temporary Workaround
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useGetList, GetListParams } from '../../libs/admin/hooks/data/useGetList'
import { DataConsumer } from '../data/DataCosumer'
import { Session } from '@supabase/supabase-js'
import { useGetSession } from '@/libs/admin/hooks/auth/useGetSession'

export const ExpenseHistory = () => {
    const { data: sessionData, isLoading: isLoadingUser } = useGetSession()
    const session = sessionData as Session
    const { data, isLoading } = useGetList(generateExpensesParams(session?.user?.id as string))
    const expenses = data?.data as TExpense[]
    const emptyData = expenses?.length == 0

    return (
        <div className='flex flex-col w-full mb-2 p-6'>
            <div className='flex items-center justify-between'>
                <SectionHeader title='Recent Expenses' />
                {!emptyData && (
                    <Link to={'/expenses'}>
                        <p className='text-xxs tracking-wide font-bold uppercase text-orange-600'>
                            {'View all'}
                        </p>
                    </Link>
                )}
            </div>
            <div className='mt-2 h-48'>
                <DataConsumer
                    isLoading={isLoading || !!isLoadingUser}
                    loader={<Loader />}
                    emptyData={emptyData}
                    emptyDataFallback={<NoDataAvailable />}
                >
                    {expenses?.map(({ ...data }: TExpense, index) => {
                        return (
                            <HistoryCard
                                key={index}
                                {...data}
                            />
                        )
                    })}
                </DataConsumer>
            </div>
        </div>
    )
}

function generateExpensesParams(userId: string) {
    return {
        queryKey: 'data-get-expense-history-dashboard',
        resource: 'expenses',
        filters: [
            {
                field: 'user_id',
                value: userId,
                operator: 'eq',
            },
        ],
        sorters: [
            {
                field: 'created_at',
                order: 'desc',
            },
        ],
        meta: {
            limit: '4',
            select: 'amount, created_at, memo',
        },
    } as GetListParams
}

const Loader = () => (
    <SkeletonTheme
        baseColor='#22303c'
        highlightColor='#2e4152'
    >
        <Skeleton
            count={4}
            height={40}
            width={250}
            className='bg-gray-50'
            enableAnimation
        />
    </SkeletonTheme>
)

const NoDataAvailable = () => (
    <div className='w-full h-full flex items-center justify-center rounded dark:text-teallight'>
        <p>No Data Available.</p>
    </div>
)
