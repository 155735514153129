import { createContext, PropsWithChildren } from 'react'
import { IDataContextProvider, IDataMultipleContextProvider } from './IDataContext'

export const defaultDataProvider = () => {
    return {
        default: {
            create: () => Promise.resolve({ data: { id: 1 } }),
            createMany: () => Promise.resolve({ data: [{ id: 1 }, { id: 1 }] }),
            deleteOne: () => Promise.resolve({ data: { id: 1 } }),
            deleteMany: () => Promise.resolve({ data: [{ id: 1 }, { id: 2 }] }),
            update: () => Promise.resolve({ data: { id: 1 } }),
            updateMany: () => Promise.resolve({ data: [{ id: 1 }, { id: 2 }] }),
            getOne: () => Promise.resolve({ data: { id: 1 } }),
            getMany: () => Promise.resolve({ data: [{ id: 1 }, { id: 2 }] }),
            getList: () => Promise.resolve({ data: [{ id: 1 }, { id: 2 }], total: 2 }),
            rpcFunction: () => Promise.resolve({ data: {} }),
            custom: () => Promise.resolve({ data: {} }),
            getApiUrl: () => '',
        },
    }
}

export const DataContext = createContext<IDataMultipleContextProvider>(
    defaultDataProvider() as IDataMultipleContextProvider,
)

DataContext.displayName = 'DataContext'

type DataContextProviderProps = PropsWithChildren<
    IDataMultipleContextProvider | IDataContextProvider
>

export const DataContextProvider: React.FC<DataContextProviderProps> = ({
    children,
    ...rest
}: DataContextProviderProps) => {
    let dataProviders
    if (!rest.getList || !rest.getOne) {
        dataProviders = rest as IDataMultipleContextProvider
    } else {
        dataProviders = {
            default: rest,
        } as IDataMultipleContextProvider
    }
    return <DataContext.Provider value={dataProviders}>{children}</DataContext.Provider>
}
