import { HttpError } from '../interfaces/errors'
import { PostgrestError } from '@supabase/supabase-js'

export const handleErrors = (error: PostgrestError) => {
    const customError: HttpError = {
        ...error,
        statusCode: parseInt(error.code),
        message: error.message,
    }

    return Promise.reject(customError)
}
