import { lazy, Suspense } from 'react'
import { Route, DefaultGenerics } from '@tanstack/react-location'
import { PageLoader } from '@/components'


const Dashboard = lazy(() => import('../features/dashboard/page/home'))
const Account = lazy(() => import('../features/dashboard/page/account'))
const Settings = lazy(() => import('../features/dashboard/page/settings'))
const Expenses = lazy(() => import('../features/dashboard/page/expenses'))

export const routes: Route<DefaultGenerics>[] = [
    {
        path: '/',
        element: <Suspense fallback={<PageLoader />}><Dashboard /></Suspense>,
        loader: () => Promise.resolve({ protected: true }),
    },
    {
        path: '/account',
        element: <Suspense fallback={<PageLoader />}><Account /></Suspense>,
        loader: () => Promise.resolve({ protected: true }),
    },
    {
        path: '/settings',
        element: <Suspense fallback={<PageLoader />}><Settings /></Suspense>,
        loader: () => Promise.resolve({ protected: true }),
    },
    {
        path: '/expenses',
        element: <Suspense fallback={<PageLoader />}><Expenses /></Suspense>,
        loader: () => Promise.resolve({ protected: true }),
    },
]
