import { useNavigate } from '@tanstack/react-location'
import useSWRMutation from 'swr/mutation'
import { AdminError } from '../../../interfaces/errors/AdminError'
import { useAuthContext } from '../useAuthContext'
import { AuthActionResponse } from '@/libs/admin/contexts/auth/IAuthContext'
import { useState } from 'react'

export type UseLoginReturnType = {
    isProcessing: boolean
    error: AdminError | Error | undefined
    handleLogin: (params: any) => void
    [key: string]: unknown
}

export const useLogin = (): UseLoginReturnType => {
    const { login } = useAuthContext()
    const navigate = useNavigate()

    const [error, setError] = useState<AdminError | Error>()

    const { isMutating, trigger } = useSWRMutation(
        'auth-logout',
        async (_, { arg }) => {
            return login?.(arg)
        },
        {
            onSuccess: async data => {  
                const { success, redirectTo, error } = data as AuthActionResponse
                if (success) {
                    if (redirectTo) {
                        navigate({ to: redirectTo, replace: true })
                    } else {
                        navigate({ to: '/', replace: true })
                    }
                }

                if (error) {
                    setError(error)
                }
            },
            onError: err => {
                setError(err)
            },
        },
    )

    return {
        isProcessing: isMutating,
        error,
        handleLogin: trigger,
    }
}
