/* eslint-disable camelcase */
import {
    ChangeEventHandler,
    Dispatch,
    FormEventHandler,
    SetStateAction,
    useEffect,
    useState,
} from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { CircularLoader } from '@/components/loader/circular-loader'
import { useGetOne, GetOneParams, useRPCFunction, useGetSession } from '@admin/hooks'

import { SubmitButton, ActionButton as CancelButton, EButtonVariant } from '@/components/button'
import { DataConsumer } from '@/components/data/DataCosumer'
import { Session } from '@supabase/supabase-js'

interface IAddButton {
    setShowAddForm: Dispatch<SetStateAction<boolean>>
}

interface ActionButtonsProps {
    canSubmit: boolean
    onSaveButtonClick?: () => void
    onCancelButtonClick: () => void
    isProcessing: boolean
    isSuccess?: boolean
}

const CATEGORIES = [
    'Home',
    'Food',
    'Travel',
    'Work',
    'Study',
    'Friends',
    'Clothes',
    'Miscellaneous',
]

const currentMonthYear = new Date().toLocaleDateString('default', {
    month: 'long',
    year: 'numeric',
})

const today = new Date()

const firstDayofMonth = new Date(today.getFullYear(), today.getMonth(), 1)
    .toISOString()
    .split('T')[0]

const lastDayToSubmit = today.toISOString().split('T')[0]

export const ExpenseAddForm = ({ setShowAddForm }: IAddButton) => {
    const [formState, setFormState] = useState({
        e_memo: '',
        e_amount: 0,
        e_date: today.toISOString(),
        remaining: 0,
        e_category: 'Home',
    })

    const { data: sessionData, isProcessing: isLoadingUser } = useGetSession()
    const session = sessionData as Session
    const user = session?.user

    const { isLoading: isAddingData, trigger: addExpense } = useRPCFunction({
        queryKey: 'data-add-new-expense',
    })

    const { data: report, isLoading: isLoadingReport } = useGetOne(getMonthlyReportParams(user?.id))
    const reportData = report?.data

    useEffect(() => {
        if (reportData) {
            setFormState({
                ...formState,
                remaining:
                    (reportData.remaining || 0) - (formState.e_amount ? formState.e_amount : 0),
            })
        }
    }, [formState.e_amount, reportData])

    const handleChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = e => {
        const name = e.currentTarget.name

        if (name == 'e_date') {
            setFormState({
                ...formState,
                e_date: new Date(e.currentTarget.value).toISOString(),
            })
            return
        }

        if (name == 'e_amount') {
            if (!Number(e.currentTarget.value) && e.currentTarget.value != '') return

            const amount = parseInt(e.currentTarget.value)

            if (amount < 0) {
                setFormState({ ...formState, [name]: 0 })
                return
            }
            if (reportData && amount > reportData.remaining) {
                setFormState({ ...formState, [e.currentTarget.name]: reportData.remaining })
                return
            }

            setFormState({ ...formState, [e.currentTarget.name]: amount })

            return
        }

        setFormState({ ...formState, [name]: e.currentTarget.value })
    }

    const canSubmit = formState.e_amount > 0 && !!formState.e_memo

    const handleSubmit: FormEventHandler<HTMLFormElement> = e => {
        e.preventDefault()
        if (!canSubmit) return

        // eslint-disable-next-line camelcase
        if (user) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { remaining, ...data } = {
                ...formState,
            }

            // rpc function to add new expense
            addExpense({
                resource: 'insert_new_expense',
                variables: data,
            }).finally(() => {
                setShowAddForm(false)
            })
        }
    }

    const hideForm = () => {
        setShowAddForm(false)
    }

    return (
        <div className='text-white w-11/12 text-sm my-2'>
            <p className='text-gray-600 dark:bg-transparent dark:text-textDark1 text-base text-center mb-4 font-semibold'>
                Expenditure Form
            </p>

            <DataConsumer
                isLoading={isLoadingReport || isLoadingUser}
                loader={<Loader />}
            >
                <form
                    id='add-new-expense'
                    className='flex flex-col px-3 py-2 text-tealdark dark:text-textDark3 items-start justify-center w-95% bg-bglight dark:bg-hoverDark rounded shadow-md'
                    onSubmit={handleSubmit}
                >
                    <div className='my-2 flex flex-col w-full'>
                        <label htmlFor='date'>Date</label>
                        <input
                            id='date'
                            name='e_date'
                            type='date'
                            value={formState.e_date.split('T')[0]}
                            max={lastDayToSubmit}
                            min={firstDayofMonth}
                            onChange={handleChange}
                            className='p-2 text-tealdark my-2 border-0.1 invalid:border-red-400 border-gray-300 rounded focus:outline-none focus:border-tealdark'
                        />
                    </div>
                    <div className='my-2 flex flex-col w-full'>
                        <label htmlFor='memo'>Memo</label>
                        <input
                            id='memo'
                            name='e_memo'
                            type='text'
                            placeholder='Purpose for expenditure'
                            onChange={handleChange}
                            value={formState.e_memo}
                            className='p-2 text-tealdark w-full my-2 border-0.1 invalid:border-red-400 border-gray-300 rounded focus:outline-none focus:border-tealdark'
                        />
                    </div>
                    <div className='my-2 flex flex-col w-full'>
                        <label htmlFor='category'>Category</label>
                        <select
                            id='category'
                            name='e_category'
                            onChange={handleChange}
                            value={formState.e_category}
                            className='p-2 text-tealdark w-full my-2 border-0.1 invalid:border-red-400 border-gray-300 rounded focus:outline-none focus:border-tealdark'
                        >
                            {CATEGORIES.map((category, index) => (
                                <option
                                    value={category}
                                    key={index}
                                >
                                    {category}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='my-2 flex flex-col w-full'>
                        <label htmlFor='amount'>Amount</label>
                        <input
                            id='amount'
                            name='e_amount'
                            type='number'
                            onChange={handleChange}
                            value={formState.e_amount}
                            placeholder='Enter amount'
                            min={0}
                            className='p-2 text-tealdark w-full my-2 border-0.1 invalid:border-red-400 border-gray-300 rounded focus:outline-none focus:border-tealdark'
                        />
                    </div>
                    <div className='my-2 flex flex-col w-full'>
                        <p className='p-2 text-tealdark text-base font-semibold dark:text-tealmedium'>
                            Remaining: {formState.remaining}
                        </p>
                    </div>
                </form>
                <ActionButtons
                    canSubmit={canSubmit}
                    isProcessing={isAddingData}
                    onCancelButtonClick={hideForm}
                />
            </DataConsumer>
        </div>
    )
}

function getMonthlyReportParams(userId: string) {
    return {
        queryKey: 'data-get-expenditure',
        resource: 'monthly_report',
        id: userId, // TODO look for better alternative
        filters: [
            {
                field: 'month',
                operator: 'eq',
                value: currentMonthYear.split(' ')[0],
            },
            {
                field: 'year',
                operator: 'eq',
                value: currentMonthYear.split(' ')[1],
            },
        ],
        meta: {
            select: 'remaining',
            idColumnName: 'user_id',
        },
    } as GetOneParams
}

const Loader = () => (
    <SkeletonTheme
        baseColor='#22303c'
        highlightColor='#2e4152'
    >
        <Skeleton
            count={1}
            height={450}
            width={275}
            className='bg-gray-50'
            enableAnimation
        />
    </SkeletonTheme>
)

const ActionButtons = (props: ActionButtonsProps) => {
    const { canSubmit, onCancelButtonClick, isProcessing } = props
    return (
        <div className='my-6 flex items-center justify-around w-full'>
            <SubmitButton
                formName='add-new-expense'
                text='Save'
                disabled={!canSubmit || isProcessing}
                isSubmitting={isProcessing}
                loader={<Circular />}
            />

            <CancelButton
                variant={EButtonVariant.CANCEL}
                disabled={isProcessing}
                text='Cancel'
                onClick={onCancelButtonClick}
            />
        </div>
    )
}

const Circular = () => (
    <CircularLoader
        height='h-6'
        width='w-6'
        color='border-orange-600'
    />
)
