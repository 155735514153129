import { useDataProvider } from './useDataProvider'
import { CrudFilters, CrudSorting, GetListResponse, MetaQuery, Pagination } from '../../interfaces'
import { AdminError } from '../../interfaces/errors/AdminError'
import useSWR from 'swr'

export interface GetListParams {
    queryKey: string
    resource: string
    pagination?: Pagination
    sorters?: CrudSorting
    filters?: CrudFilters
    meta?: MetaQuery
}

export interface GetListReturnType {
    data: GetListResponse
    isLoading: boolean
    error: AdminError | Error
}

export const useGetList = ({
    queryKey,
    resource,
    pagination,
    sorters,
    filters,
    meta,
}: GetListParams) => {
    const dataProvider = useDataProvider()

    const { data, isLoading, error } = useSWR(queryKey, async () => {
        return await dataProvider().getList({
            resource,
            pagination,
            sorters,
            filters,
            meta,
        })
    })

    return {
        data,
        isLoading,
        error,
    } as GetListReturnType
}
