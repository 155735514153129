/* eslint-disable camelcase */

import { TExpense } from '../../types'

export const HistoryCard = ({ amount, created_at, memo, ...otherProps }: TExpense) => {
    const formattedDate = formatDate(created_at)

    return (
        <div
            className='flex items-start justify-between py-2 w-full'
            {...otherProps}
        >
            <div className='flex flex-col items-start'>
                <p className='text-gray-600 dark:text-textDark3 text-sm'>{memo}</p>
                <p className='text-gray-500 text-xs'>Rs {amount} /-</p>
            </div>
            <p className='my-1 text-xxs text-tealdark dark:text-gray-500'>{formattedDate}</p>
        </div>
    )
}


function formatDate(created_at: string){
    const formatOptions: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    const formattedDate = new Date(created_at).toLocaleDateString('en-US', formatOptions)
    return formattedDate.replace(',', '');
}