import React from 'react'

import { DataContextProvider, defaultDataProvider, AuthContextProvider } from '../../contexts'

import {
    IDataContextProvider,
    IDataMultipleContextProvider,
} from '../../contexts/data/IDataContext'
import { IAuthContextProvider } from '../../contexts/auth/IAuthContext'

export interface AdminProps {
    children?: React.ReactNode
    /**
     * A `dataProvider` is the place where a refine app communicates with an API. Data providers also act as adapters for refine, making it possible for it to consume different API's and data services.
     * @type [`IDataContextProvider` | `IDataMultipleContextProvider`]
     */
    dataProvider: IDataContextProvider | IDataMultipleContextProvider
    /**
     * `authProvider` handles authentication logic like login, logout flow and checking user credentials. It is an object with methods that refine uses when necessary.
     * @type [`AuthBindings`]
     */
    authProvider: IAuthContextProvider
}

export const Admin = ({ children, dataProvider, authProvider }: AdminProps) => {
    if (!dataProvider) {
        dataProvider = defaultDataProvider()
    }

    return (
        <AuthContextProvider {...authProvider}>
            <DataContextProvider {...dataProvider}>
                {children}
            </DataContextProvider>
        </AuthContextProvider>
    )
}
