import { useNavigate } from '@tanstack/react-location';


export const PageNotFound = () => {

    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate({ to: '/' })
    }

    return (
        <div className='bg-bgMain dark:bg-bgDark h-screen w-screen p-16'>
            <div className='w-full flex flex-col items-center justify-center'>
                <div className='bg-404Image inline-block h-40vh w-22vw bg-center bg-cover' />
                <p className='text-subheading mt-8 text-tealdark font-sans tracking-tight'>
                    Whoops!!!
                </p>
                <p className="text-gray-500 text-sm mt-2">
                    We couldn&apos;t find the page you are looking for.
                </p>
                <button 
                    className="bg-tealdark px-6 py-3 text-white mt-16 rounded shadow-md drop-shadow-md hover:bg-tealmedium"
                    onClick={handleOnClick}
                >
                    Go to homepage
                </button>
            </div>
        </div>
    )
}
