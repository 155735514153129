import { Route, DefaultGenerics } from '@tanstack/react-location'
import { Loader } from '@/components/loader/loader'

import Login from '@/features/authentication/login'
import Register from '@/features/authentication/register'
import { PageNotFound } from '@/pages/404'
import { Onboarding } from '@/pages/Onboarding'

export const routes: Route<DefaultGenerics>[] = [
    { path: '/login', element: <Login />, loader: () => ({ pageType: 'full' }) },
    { path: '/register', element: <Register />, loader: () => ({ pageType: 'full' }) },
    { path: '/onboarding', element: <Onboarding />, loader: () => ({ pageType: 'full' }) },
    {
        path: '*',
        element: <PageNotFound />,
        loader: () => ({ pageType: 'full' }),
        pendingElement: <Loader />,
    },
]
