import { ReactElement } from 'react'
import { EButtonSize } from './ActionButton'

export type SubmitButtonProps = {
    disabled: boolean
    formName: string
    text: string
    isSubmitting: boolean
    loader: ReactElement
    size?: EButtonSize
}

export const SubmitButton = ({
    disabled,
    formName,
    text,
    isSubmitting,
    loader,
    size = EButtonSize.DEFAULT,
    ...otherProps
}: SubmitButtonProps) => {
    return (
        <button
            type='submit'
            form={formName}
            className={
                'flex items-center justify-around bg-orange-600 hover:bg-orange-500 border border-orange-600 dark:bg-transparent dark:border dark:border-green-600 dark:hover:bg-green-600 disabled:bg-gray-400 disabled:text-gray-100 disabled:border-gray-400 rounded shadow-xl dark:disabled:bg-transparent dark:disabled:border-borderDark ' +
                size
            }
            disabled={disabled}
            {...otherProps}
        >
            {isSubmitting && loader}
            {text}
        </button>
    )
}
