import useSWR, { KeyedMutator, useSWRConfig } from 'swr'
import { useAuthContext } from '../useAuthContext'
import { SessionResponse } from '../../../contexts/auth/IAuthContext'

export type UseGetSessionProps = {
    params: any
}

export interface UseGetSessionReturnType {
    isProcessing: boolean
    data: SessionResponse | undefined
    mutate: KeyedMutator<unknown>
    [type: string]: unknown
}

export const useGetSession = (params?: UseGetSessionProps): UseGetSessionReturnType => {
    const { getSession } = useAuthContext()

    const { mutate: mutateUser } = useSWRConfig()

    const { data, isLoading, error, mutate } = useSWR(
        'auth-getSession', 
        async () => {
            return await getSession?.(params)
        },
        {
            onSuccess: (data) => {
                mutateUser(key => typeof key === 'string' && key === 'auth-getIdentity')
                return data
            }
        }
    )

    return {
        isProcessing: isLoading,
        data,
        error,
        mutate,
    }
}
