import { Session } from '@supabase/supabase-js'
import { Navigate } from '@tanstack/react-location'

import { PageNotFound } from '@/pages/404'
import { useGetSession } from '@/libs/admin'
import { PageLoader } from '@/components/loader/page-loader'

export type AuthChildrenProps = {
    session: Session
}

export type AuthConsumerProps = {
    redirect?: boolean
    children: (props: AuthChildrenProps) => JSX.Element
}

export const AuthConsumer = ({ redirect, children }: AuthConsumerProps) => {
    const { data, isProcessing } = useGetSession()

    const session = data as Session

    if (isProcessing) {
        return <PageLoader />
    }

    if (!session && redirect) {
        return (
            <Navigate
                to='/login'
                replace
            />
        )
    }

    if (!session) {
        return <PageNotFound />
    }

    return <>{children({ session })}</>
}
