import { useCallback, useContext } from 'react'

import { DataContext } from '../../contexts'

import {
    IDataMultipleContextProvider,
    IDataContextProvider,
} from '../../contexts/data/IDataContext'

export const useDataProvider = (): ((
    /*
     * The name of the provider that you want to access
     */
    dataProviderName?: string,
) => IDataContextProvider) => {
    const context = useContext<IDataMultipleContextProvider>(DataContext)

    const handleDataProvider = useCallback(
        (dataProviderName?: string) => {
            if (dataProviderName) {
                const dataProvider = context[dataProviderName]
                if (!dataProvider) {
                    throw new Error(`
                        "${dataProviderName}" Data provider not found`)
                }
                return dataProvider
            }

            if (context.default) {
                return context.default
            } else {
                throw new Error(
                    'There is no "default" data provider. Please pass dataProviderName'
                )
            }
        },
        [context],
    )

    return handleDataProvider
}
