import React, { PropsWithChildren, createContext } from 'react'
import { IAuthContextProvider as IAuthContext } from './IAuthContext'

export const AuthContext = createContext<Partial<IAuthContext>>({})

export const AuthContextProvider: React.FC<PropsWithChildren<IAuthContext>> = ({
    children,
    ...authProvider
}) => {
    const handleLogin = async (params: unknown) => {
        try {
            const result = await authProvider.login?.(params)

            return result
        } catch (error) {
            console.warn('Unhandled Error in login: the promise should always be resolved', error)
            return Promise.reject(error)
        }
    }

    const handleRegister = async (params: unknown) => {
        try {
            const result = await authProvider.register?.(params)

            return result
        } catch (error) {
            console.warn(
                'Unhandled Error in register: the promise should always be resolved',
                error,
            )
            return Promise.reject(error)
        }
    }

    const handleLogout = async (params: unknown) => {
        try {
            const result = await authProvider.logout?.(params)

            return result
        } catch (error) {
            console.warn('Unhandled Error in logout: the promise should always be resolved', error)
            return Promise.reject(error)
        }
    }

    const handleCheck = async (params: unknown) => {
        try {
            const result = await authProvider.check?.(params)

            return result
        } catch (error) {
            console.warn('Unhandled Error in check: the promise should always be resolved', error)
            return Promise.reject(error)
        }
    }

    const handleForgotPassword = async (params: unknown) => {
        try {
            const result = await authProvider.forgotPassword?.(params)

            return result
        } catch (error) {
            console.warn(
                'Unhandled Error in forgotPassword: the promise should always be resolved',
                error,
            )
            return Promise.reject(error)
        }
    }

    const handleUpdatePassword = async (params: unknown) => {
        try {
            const result = await authProvider.updatePassword?.(params)

            return result
        } catch (error) {
            console.warn(
                'Unhandled Error in updatePassword: the promise should always be resolved',
                error,
            )
            return Promise.reject(error)
        }
    }

    return (
        <AuthContext.Provider
            value={{
                ...authProvider,
                login: handleLogin as IAuthContext['login'],
                register: handleRegister as IAuthContext['register'],
                logout: handleLogout as IAuthContext['logout'],
                check: handleCheck as IAuthContext['check'],
                forgotPassword: handleForgotPassword as IAuthContext['forgotPassword'],
                updatePassword: handleUpdatePassword as IAuthContext['updatePassword'],
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
