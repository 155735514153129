import { icons } from '../../assets/icons'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useGetOne, GetOneParams } from '../../libs/admin/hooks/data/useGetOne'
import { GetOneResponse } from '../../libs/admin/interfaces'
import { DataConsumer } from '../data/DataCosumer'
import { Session } from '@supabase/supabase-js'
import useSWR from 'swr'

const currentMonthYear = new Date().toLocaleDateString('default', {
    month: 'long',
    year: 'numeric',
})

export const HighestExpense = () => {
    const { data: sessionData, isLoading: isFetchingSession } = useSWR('auth-getSession')
    const session = sessionData as Session
    const { data, isLoading } = useGetOne(
        generateExpenseCategoryParams(session?.user?.id as string),
    )
    const categories = (data?.data as GetOneResponse) || {}
    const { category, expense } = getHighestExpenseForCategory(categories)
    const emptyData = expense <= 0

    return (
        <div className='w-full px-6 py-4'>
            <div className='flex flex-col w-full items-center justify-between bg-bglight dark:bg-hoverDark'>
                <p className='text-tealdark dark:text-textDark3 text-center text-lg my-3'>
                    Highest Expense
                </p>

                <DataConsumer
                    isLoading={isLoading || isFetchingSession}
                    loader={<Loader />}
                    emptyData={emptyData}
                    emptyDataFallback={<NoDataAvailable />}
                >
                    <>
                        <img
                            src={icons[category as keyof typeof icons]}
                            className='w-8 h-8'
                        />
                        <p className='leading-4 text-text-gray-500 dark:text-gray-500 capitalize mt-4 mb-1'>
                            {category}
                        </p>
                        <p className='text-text-gray-500 text-sm dark:text-gray-500'>
                            Rs{expense.toLocaleString('en-US')}
                        </p>
                    </>
                </DataConsumer>
            </div>
        </div>
    )
}

const getHighestExpenseForCategory = ({ ...array }) => {
    const entries = Object.entries(array)
    return entries.reduce(
        (highest, value) => {
            return parseInt(value[1]) > highest.expense
                ? { category: value[0], expense: value[1] }
                : highest
        },
        { category: '', expense: 0 },
    )
}

function generateExpenseCategoryParams(userId: string) {
    return {
        queryKey: 'data-get-highest-expense',
        resource: 'expense_category_data',
        id: userId,
        filters: [
            {
                field: 'month',
                operator: 'eq',
                value: currentMonthYear.split(' ')[0],
            },
            {
                field: 'year',
                operator: 'eq',
                value: currentMonthYear.split(' ')[1],
            },
        ],
        meta: {
            idColumnName: 'user_id',
            select: 'home, work, travel, friends, clothes, study, food, miscellaneous',
        },
    } as GetOneParams
}

const Loader = () => (
    <SkeletonTheme
        baseColor='#22303c'
        highlightColor='#2e4152'
    >
        <div className='m-auto w-fit'>
            <Skeleton
                count={1}
                height={85}
                width={250}
            />
        </div>
    </SkeletonTheme>
)

const NoDataAvailable = () => (
    <div className='flex flex-col w-full items-center justify-around bg-bglight dark:bg-hoverDark h-20 my-1'>
        <p className='dark:text-teallight'>No Data Available.</p>
    </div>
)
