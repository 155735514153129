interface ILogo {
    size?: string
    name?: boolean
    absolute?: boolean
}

export const TextLogo = ({ size = 'medium', name = false, absolute = false }: ILogo) => {
    const textSize =
        size === 'small'
            ? ['text-2xl', 'text-4xl']
            : size === 'medium'
            ? ['text-3xl', 'text-5xl']
            : ['text-4xl', 'text-6xl']

    const position = absolute ? ' absolute bottom-10' : '';
    return (
        <div className={'text-center'+ position}>
            <p className={'font-bold drop-shadow-lg text-tealdark ' + textSize[0]}>
                P<span className={textSize[1]}>€</span>T
            </p>
            {name ? (
                <>
                    <p className={'text-tealdark font-semibold text-sm'}>Personal Expense Tracker</p>
                    <p className={'text-gray-500 font-bold text-xxs mt-2'}>@Haatemalotech</p>
                </>
            ) : null}
        </div>
    )
}
