import { Line } from 'react-chartjs-2'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

interface IObjectKeys {
    title: string
    data: object
}

const getChartData = ({ title, data }: IObjectKeys) => {
    const labels = Object.keys({ ...data })
    const dataset = Object.values({ ...data })
    return {
        datasetIdKey: title,
        labels: labels,
        datasets: [
            {
                data: dataset,
                backgroundColor: '#ff2400',
                borderColor: '#ff2400',
            },
        ],
    }
}

export const LineChart = ({ title, data }: IObjectKeys) => {
    const minValue =
        Math.min(...Object.values({ ...data }).map((element: any) => parseInt(element))) - 1000
    return (
        <div className='flex flex-col items-start mt-0 px-6 py-2'>
            <p className='mb-6 text-start text-sm text-tealdark dark:text-textDark3 dark:font-normal'>
                {title}
            </p>
            <div className='h-48'>
                <Line
                    data={getChartData({ title, data })}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            tooltip: {
                                animation: {
                                    easing: 'easeInCubic',
                                },
                            },
                            legend: {
                                display: false,
                            },
                        },
                        scales: {
                            y: {
                                min: minValue,
                            },
                        },
                    }}
                />
            </div>
        </div>
    )
}
