export type DividerProps = {
    gap: string
}

export const VerticalDivider = ({ gap }: DividerProps) => (
    <div className={'bg-transparent h-' + gap}></div>
)
export const HorizontalDivider = ({ gap }: DividerProps) => (
    <div className={'bg-transparent w-' + gap}></div>
)
