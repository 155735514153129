import { CrudOperators } from '../contexts/data/IDataContext'

export const mapOperator = (operator: CrudOperators) => {
    switch (operator) {
        case 'neq':
            return 'neq'
        case 'nin':
            return 'not.in'
        case 'contains':
            return 'ilike'
        case 'ncontains':
            return 'not.ilike'
        case 'containss':
            return 'like'
        case 'ncontainss':
            return 'not.like'
        case 'null':
            return 'is'
        case 'nnull':
            return 'not.is'
        case 'between':
        case 'nbetween':
            throw Error(`Operator ${operator} is not supported`)
        default:
            return operator
    }
}
