import { useState, ChangeEvent } from 'react'
import { useNavigate } from '@tanstack/react-location'

import { CircularLoader } from '@/components/loader/circular-loader'
import OnBoardingPic from '../assets/images/onboarding.png'
import { TextLogo } from '@/components/text-logo/text-logo'
import { Session } from '@supabase/supabase-js'
import { AuthChildrenProps, AuthConsumer } from '@/components/auth/AuthConsumer'
import { PageNotFound } from '@/pages/404'
import { GetOneParams, useGetOne, useRPCFunction } from '@/libs/admin'

export const Onboarding = () => {
    return (
        <AuthConsumer>
            {(props: AuthChildrenProps) => <OnboardingPage session={props.session} />}
        </AuthConsumer>
    )
}

export const OnboardingPage = ({ session }: { session: Session }) => {
    const [monthlyIncome, setMonthlyIncome] = useState<number>(0)
    const navigate = useNavigate()

    const { data } = useGetOne(
        generateProfileStatusParams(session.user.id)
    )

    const { isLoading: isSubmitting, trigger: completeOnboarding } = useRPCFunction({ queryKey: 'data-complete-onboarding' })

    const isOnboarded = data?.data.onboarded

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setMonthlyIncome(e.target.value ? parseInt(e.target.value) : 0)
    }

    const handleButtonClick = () => {
        if (monthlyIncome < 0) return

        completeOnboarding({
            resource: 'complete_user_onboarding',
            variables: {
                userid: session.user.id,
                income: monthlyIncome,
            }
        })
        .then(() => {            
            navigate({ to: '/' })
        })
        .catch(error => {
            throw error
        })
    }

    if (isOnboarded) {
        return <PageNotFound />
    }

    return (
        <div className='flex items-center justify-between bg-bgMain h-screen w-full'>
            <div className='w-3/5 h-full'>
                <img
                    src={OnBoardingPic}
                    className='h-full w-full'
                />
            </div>
            <div className='flex flex-col items-center justify-start px-8 py-16 h-full w-2/5'>
                <TextLogo name />

                <p className='mt-16 text-tealdark text-4xl font-sans font-semibold'>Welcome</p>
                <p className='text-tealdark font-sans font-semibold'>
                    {session.user.user_metadata.name}
                </p>
                <p className='mt-2 text-sm text-gray-400'>
                    You will be onboarding with us after this step.
                </p>
                <p className='text-sm text-gray-400 leading-3'>
                    We need this information to complete your profile.
                </p>
                <div className='flex flex-col items-center text-gray-700 mt-14'>
                    <label
                        htmlFor='income'
                        className='ml-1 mb-1 text-tealdark text-sm'
                    >
                        Please provide your monthly income.
                    </label>
                    <input
                        name='income'
                        type='number'
                        className='pl-2 py-3 pr-10 rounded bg-gray-200 border border-gray-400'
                        placeholder='Eg: 50000'
                        onChange={handleInputChange}
                    />
                    <div className='flex items-center justify-center'>
                        <button
                            className='my-12 px-8 py-3 bg-tealdark hover:bg-tealmedium text-white rounded shadow-lg drop-shadow-lg disabled:bg-gray-500'
                            onClick={handleButtonClick}
                            disabled={isSubmitting || monthlyIncome < 1}
                        >
                            Proceed
                        </button>
                        {isSubmitting && (
                            <span className='ml-6'>
                                <CircularLoader />
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}


function generateProfileStatusParams (userId: string) {
    return {
        queryKey: 'data-get-onboarded-status',
        resource: 'profiles',
        id: userId,
        meta: {
            select: 'onboarded'
        }
    } as GetOneParams
}