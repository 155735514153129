import { useSWRConfig } from 'swr'

export const useRevalidateAuthStore = () => {
    const { mutate } = useSWRConfig()
    const revalidate = () => {
        mutate(key => typeof key === 'string' && key.startsWith('auth-'))
    }

    return {
        revalidate,
    }
}
