import { useEffect, useState } from 'react'
import { CreateResponse, MetaQuery } from '../../interfaces'
import { useDataProvider } from './useDataProvider'

export interface CreateOneParams {
    resource: string
    variables: object
    meta?: MetaQuery
}

export const useCreateOne = ({ resource, variables, meta }: CreateOneParams) => {
    const dataProvider = useDataProvider()
    const [data, setData] = useState <CreateResponse>()
    const [isLoading, setIsLoading] = useState<boolean>(true)


    useEffect(() => {
        let mounted = true;
        if(mounted){
            dataProvider()
            .create({
                resource,
                variables,
                meta
            })
            .then(response => {
                setData(response)
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
        }


        // cleanup function
        return () => {
            mounted = false
        }
    }, [])

    return {
        isLoading,
        data,
    }
}
